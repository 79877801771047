// Polyfills for IE 10 (@see https://github.com/gatsbyjs/gatsby/issues/2177#issuecomment-382280801)
// require('core-js/fn/set');
// require('core-js/fn/map');
// require('core-js/fn/array/from');
// require('core-js/fn/object/entries');
// require('core-js/fn/string/starts-with');
require('core-js/fn/string/match-all');

// IO Polyfill für Safari und iOS
// require('intersection-observer');
